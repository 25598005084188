.featured-blog-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    height: 100%;
}

.featured-blog-wrapper .featured-blog-image {
    max-height: 12rem;
    object-fit: cover;
}

.featured-blog-wrapper iframe {
    min-height: 0 !important;
    max-height: 100%;
    height: 100%;
}

@media only screen and (max-width: 768px) {
    .featured-blog-wrapper .featured-blog-image {
        max-height: 100%;
    }
    
    .featured-blog-wrapper iframe {
        min-height: 480px !important;
    }
}
