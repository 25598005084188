.benchmarking-title-wrapper {
    display: flex;
    flex-direction: row;
    gap: 1rem;
     .tooltip-content {
        width: 30rem;
        height: fit-content;
        @media(max-width: 900px){
            width: 20rem;
        }
    }
     .tooltip-icon {
        font-size: 2rem;
        align-items: flex-start;
        background: #8898aa;
        color: #fff;
        width: 45px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
    }
     .tooltip-content {
        left: 3rem !important;
        @media(max-width: 700px) {
            top: 4rem;
            left: -8rem !important;
        }
        @media(max-width: 520px) {
            left: -13rem !important;
        }
        @media(max-width: 440px) {
            left: -16rem !important;
        }
        @media(max-width: 380px) {
            left: -18rem !important;
        }
    }
}

.tooltip-content {
    background-color:  #8898aa;
    color: white;
    opacity: 0;
    border-radius: 2px;
    font-size: .875rem;
    padding:  .375rem .75rem;
    transition:  all ease-in-out .1s;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 2rem;
    height: 9rem;
    z-index: 15678765;
    width: 20rem;
    display: none;
}
.tooltip-full {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    position: relative;
}
.tooltip-full:hover .tooltip-content {
    opacity: 1;
    transition:  opacity ease-in-out .1s;
    display: block;
}

.tooltip-icon {
    cursor: pointer;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
}

.benchmarking-filter-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.benchmarking-filter-group .filter-group-1, .benchmarking-filter-group .filter-group-2 {
    width: calc(100%/ 2 - 10px);
}


.benchmarking-filter-title {
    font-weight: 600;
}
@media(max-width: 500px){
    .tooltip-content {
        right: 0;
        top: 40px;
        width: 14rem;
    }
}

